import React, { Fragment } from "react";
import { useState } from "react";

import ResultTable from "../components/meddra_approval_report/resultsTable";
import { CSVDownloadButton } from "components/helpers";
import { Button } from "react-bootstrap";
import { getCsrfToken } from "../services/csrf_helper";

const MeddraApprovalsReportPage = ({ studyId, data }) => {
  const buildCSVData = () => {
    return data.map((form) => {
      return [
        form.raw_term,
        form.llt_term,
        form.pt_term,
        form.soc_term,
        form.approval_status,
        form.created_by,
        form.created_at,
      ];
    });
  };

  const csvData = buildCSVData();

  const [approvedCombinations, setApprovedCombinations] = useState([]);
  const [inappropriateCombinations, setInappropriateCombinations] = useState(
    []
  );

  const filteredData = data.filter((datum) => {
    return !datum.approval_status;
  });

  const selectApprovedForm = (form, value) => {
    selectForm(form, value, approvedCombinations, setApprovedCombinations);
    if (value) {
      selectForm(
        form,
        false,
        inappropriateCombinations,
        setInappropriateCombinations
      );
    }
  };

  const selectInappropriateForm = (form, value) => {
    selectForm(
      form,
      value,
      inappropriateCombinations,
      setInappropriateCombinations
    );
    if (value) {
      selectForm(form, false, approvedCombinations, setApprovedCombinations);
    }
  };

  const selectForm = (form, value, state, setter) => {
    const formInArray = state.includes(form);

    if (formInArray && value == false) {
      const newSelectedForms = state.filter((item) => {
        return item != form;
      });
      setter(newSelectedForms);
    }

    if (!formInArray && value == true) {
      const newSelectedForms = [...state, form];
      setter(newSelectedForms);
    }
  };

  const csrfToken = getCsrfToken();

  const buildForm = () => {
    const postLocation = `/studies/${studyId}/reports/meddra_approvals/submit_terms`;

    const buildApprovedInputs = () => {
      return approvedCombinations.map((form, index) => {
        return (
          <Fragment key={`approved-${index}`}>
            <input
              type="hidden"
              name="approved_forms[]raw_term"
              value={form.raw_term}
            ></input>
            <input
              type="hidden"
              name="approved_forms[]llt_code"
              value={form.llt_code}
            ></input>
            <input
              type="hidden"
              name="approved_forms[]llt_term"
              value={form.llt_term}
            ></input>
            <input
              type="hidden"
              name="approved_forms[]pt_code"
              value={form.pt_code}
            ></input>
            <input
              type="hidden"
              name="approved_forms[]pt_term"
              value={form.pt_term}
            ></input>
            <input
              type="hidden"
              name="approved_forms[]soc_code"
              value={form.soc_code}
            ></input>
            <input
              type="hidden"
              name="approved_forms[]soc_term"
              value={form.soc_term}
            ></input>
          </Fragment>
        );
      });
    };

    const buildInappropriateInputs = () => {
      return inappropriateCombinations.map((form, index) => {
        return (
          <Fragment key={`inappropriate-${index}`}>
            <input
              type="hidden"
              name="inappropriate_forms[]raw_term"
              value={form.raw_term}
            ></input>
            <input
              type="hidden"
              name="inappropriate_forms[]llt_code"
              value={form.llt_code}
            ></input>
            <input
              type="hidden"
              name="inappropriate_forms[]llt_term"
              value={form.llt_term}
            ></input>
            <input
              type="hidden"
              name="inappropriate_forms[]pt_code"
              value={form.pt_code}
            ></input>
            <input
              type="hidden"
              name="inappropriate_forms[]pt_term"
              value={form.pt_term}
            ></input>
            <input
              type="hidden"
              name="inappropriate_forms[]soc_code"
              value={form.soc_code}
            ></input>
            <input
              type="hidden"
              name="inappropriate_forms[]soc_term"
              value={form.soc_term}
            ></input>
          </Fragment>
        );
      });
    };

    return (
      <form
        action={postLocation}
        method="post"
        className="mb-2 d-flex justify-content-end"
      >
        <input
          type="hidden"
          name="authenticity_token"
          value={csrfToken || ""}
        />
        {buildApprovedInputs()}
        {buildInappropriateInputs()}
        <Button
          variant="primary"
          type="submit"
          disabled={
            approvedCombinations.length == 0 &&
            inappropriateCombinations.length == 0
          }
          data-confirm="This cannot be undone, are you sure you would like to submit the selected forms"
        >
          Submit Selected Forms
        </Button>
      </form>
    );
  };

  return (
    <>
      {csvData.length > 0 && (
        <div className="mb-4">
          <CSVDownloadButton
            headers={[
              "Raw Term",
              "LLT",
              "PT",
              "SOC",
              "Status",
              "Approved/Inappropriate By",
              "Approved/Inappropriate At",
            ]}
            data={csvData}
            filename={`${studyId}_meddra_approvals_report`}
            addTimeStamp={true}
            active={csvData.length > 0}
          >
            Download results for {csvData.length} form
            {csvData.length == 1 ? null : "s"}
          </CSVDownloadButton>
        </div>
      )}
      {filteredData.length == 0 ? (
        <div className="text-muted">
          There are no available combinations to review.
        </div>
      ) : (
        <>
          {buildForm()}
          <ResultTable
            forms={filteredData}
            approvedCombinations={approvedCombinations}
            inappropriateCombinations={inappropriateCombinations}
            selectApprovedForm={selectApprovedForm}
            selectInappropriateForm={selectInappropriateForm}
          ></ResultTable>
        </>
      )}
    </>
  );
};

export default MeddraApprovalsReportPage;
